<template>
  <div class="h-box-shadow">
    <el-table
      v-loading="table.loading"
      :data="table.data"
      max-height="500px"
      height="400px"
      size="medium"
      stripe
      tooltip-effect="light">
      <el-table-column
        prop="Sample_ID"
        min-width="180"
        label="Sample ID"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Sample_Name"
        min-width="180"
        label="Sample Name"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Sample_fractions"
        min-width="180"
        label="Sample fractions"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="Age"
        min-width="180"
        label="Age (for caculating initial value)"
        show-overflow-tooltip>
      </el-table-column>

      <el-table-column label="Rb-Sr isotopes" header-align="center">
        <el-table-column
          prop="Rb_Rb"
          min-width="180"
          label="Rb"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Rb_Sr"
          min-width="180"
          label="Sr"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Rb_87Rb_86Sr"
          min-width="180"
          label="87Rb/86Sr"
          show-overflow-tooltip>
          <template slot="header"><sup>87</sup>Rb/<sup>86</sup>Sr</template>
        </el-table-column>
        <el-table-column
          prop="Rb_87Rb_86Sr_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Rb_87Sr_86Sr"
          min-width="180"
          label="Rb_87Sr_86Sr"
          show-overflow-tooltip>
          <template slot="header"><sup>87</sup>Sr/<sup>86</sup>Sr</template>
        </el-table-column>
        <el-table-column
          prop="Rb_87Sr_86Sr_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column
          prop="87Rb decay constant"
          min-width="180"
          label="87Rb decay constant"
          show-overflow-tooltip>
          <template slot="header"><sup>87</sup>Rb decay constant</template>
        </el-table-column> -->
      </el-table-column>

      <el-table-column label="Sm-Nd isotopes" header-align="center">
        <el-table-column
          prop="Sm_Sm"
          min-width="180"
          label="Sm"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Sm_Nd"
          min-width="180"
          label="Nd"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Sm_147Sm_144Nd"
          min-width="180"
          label="147Sm/144Nd"
          show-overflow-tooltip>
          <template slot="header"><sup>147</sup>Sm/<sup>144</sup>Nd</template>
        </el-table-column>
        <el-table-column
          prop="Sm_147Sm_144Nd_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Sm_143Nd_144Nd"
          min-width="180"
          label="143Nd/144Nd"
          show-overflow-tooltip>
          <template slot="header"><sup>143</sup>Nd/<sup>144</sup>Nd</template>
        </el-table-column>
        <el-table-column
          prop="Sm_143Nd_144Nd_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
      </el-table-column>

      <el-table-column label="Pb isotopes" header-align="center">
        <el-table-column
          prop="Pb_206Pb_204Pb"
          min-width="180"
          label="206Pb/204Pb"
          show-overflow-tooltip>
          <template slot="header"><sup>206</sup>Pb/<sup>204</sup>Pb</template>
        </el-table-column>
        <el-table-column
          prop="Pb_206Pb_204Pb_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Pb_207Pb_204Pb"
          min-width="180"
          label="207Pb/204Pb"
          show-overflow-tooltip>
          <template slot="header"><sup>207</sup>Pb/<sup>204</sup>Pb</template>
        </el-table-column>
        <el-table-column
          prop="Pb_207Pb_204Pb_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Pb_208Pb_204Pb"
          min-width="180"
          label="208Pb/204Pb"
          show-overflow-tooltip>
          <template slot="header"><sup>208</sup>Pb/<sup>204</sup>Pb</template>
        </el-table-column>
        <el-table-column
          prop="Pb_208Pb_204Pb_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
      </el-table-column>

      <el-table-column label="Lu-Hf isotopes" header-align="center">
        <el-table-column
          prop="Lu_Lu"
          min-width="180"
          label="Lu"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Lu_Hf"
          min-width="180"
          label="Hf"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Lu_176Yb177Hf"
          min-width="180"
          label="176Yb177Hf (only used for LA-ICPMS)"
          show-overflow-tooltip>
          <template slot="header"><sup>176</sup>Yb<sup>177</sup>Hf</template>
        </el-table-column>
        <el-table-column
          prop="Lu_176Yb177Hf_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Lu_176Lu_177Hf"
          min-width="180"
          label="176Lu/177Hf"
          show-overflow-tooltip>
          <template slot="header"><sup>176</sup>Lu/<sup>177</sup>Hf</template>
        </el-table-column>
        <el-table-column
          prop="Lu_176Lu_177Hf_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Lu_176Hf_177Hf"
          min-width="180"
          label="176Hf/177Hf"
          show-overflow-tooltip>
          <template slot="header"><sup>176</sup>Hf/<sup>177</sup>Hf</template>
        </el-table-column>
        <el-table-column
          prop="Lu_176Hf_177Hf_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
      </el-table-column>

      <el-table-column label="Re-Os isotopes" header-align="center">
        <el-table-column
          prop="Re_Re"
          min-width="180"
          label="Re"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Re_Os"
          min-width="180"
          label="Os"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Re_187Re_188Os"
          min-width="180"
          label="187Re/188Os"
          show-overflow-tooltip>
          <template slot="header"><sup>187</sup>Re<sup>188</sup>Os</template>
        </el-table-column>
        <el-table-column
          prop="Re_187Re_188Os_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="Re_187Os_188Os"
          min-width="180"
          label="187Os/188Os"
          show-overflow-tooltip>
          <template slot="header"><sup>187</sup>Os<sup>188</sup>Os</template>
        </el-table-column>
        <el-table-column
          prop="Re_187Os_188Os_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
      </el-table-column>

      <el-table-column label="O isotopes" header-align="center">
        <el-table-column
          prop="O_16O"
          min-width="180"
          label="16O"
          show-overflow-tooltip>
          <template slot="header"><sup>16</sup>O</template>
        </el-table-column>
        <el-table-column
          prop="O_17O"
          min-width="180"
          label="17O"
          show-overflow-tooltip>
          <template slot="header"><sup>17</sup>O</template>
        </el-table-column>
        <el-table-column
          prop="O_18O"
          min-width="180"
          label="18O"
          show-overflow-tooltip>
          <template slot="header"><sup>18</sup>O</template>
        </el-table-column>
        <el-table-column
          prop="O_18O_16O"
          min-width="180"
          label="18O/16O"
          show-overflow-tooltip>
          <template slot="header"><sup>18</sup>O/<sup>16</sup>O</template>
        </el-table-column>
        <el-table-column
          prop="O_18O_16O_1SE"
          min-width="180"
          label="1SE%"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="O_17O_16O"
          min-width="180"
          label="17O/16O"
          show-overflow-tooltip>
          <template slot="header"><sup>17</sup>O/<sup>16</sup>O</template>
        </el-table-column>
        <el-table-column
          prop="O_17O_16O_1SE"
          min-width="180"
          label="1SE%"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="O_18OVSMOW"
          min-width="180"
          label="δ18OVSMOW"
          show-overflow-tooltip>
          <template slot="header">δ<sup>18</sup>O<sub>VSMOQ</sub></template>
        </el-table-column>
        <el-table-column
          prop="O_18OVSMOW_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="O_17OVSMOW"
          min-width="180"
          label="δ17OVSMOW"
          show-overflow-tooltip>
          <template slot="header">δ<sup>17</sup>O<sub>VSMOQ</sub></template>
        </el-table-column>
        <el-table-column
          prop="O_17OVSMOW_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="O_17O_2"
          min-width="180"
          label="Δ17O"
          show-overflow-tooltip>
          <template slot="header">Δ<sup>17</sup>O</template>
        </el-table-column>
        <el-table-column
          prop="O_17O_2_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
      </el-table-column>

      <el-table-column label="Other stable isotopes" header-align="center">
        <el-table-column
          prop="delta_D"
          min-width="180"
          label="δD"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="delta_D_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column
          prop="delta_7Li"
          min-width="180"
          label="δ7Li"
          show-overflow-tooltip>
          <template slot="header">δ<sup>7</sup>Li</template>
        </el-table-column>
        <el-table-column
          prop="delta_7Li_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="delta_11B"
          min-width="180"
          label="δ11B"
          show-overflow-tooltip>
          <template slot="header">δ<sup>11</sup>B</template>
        </el-table-column>
        <el-table-column
          prop="delta_11B_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="delta_13C"
          min-width="180"
          label="δ13C"
          show-overflow-tooltip>
          <template slot="header">δ<sup>13</sup>C</template>
        </el-table-column>
        <el-table-column
          prop="delta_13C_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="delta_15N"
          min-width="180"
          label="δ15N"
          show-overflow-tooltip>
          <template slot="header">δ<sup>15</sup>N</template>
        </el-table-column>
        <el-table-column
          prop="delta_15N_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="delta_25Mg"
          min-width="180"
          label="δ25Mg"
          show-overflow-tooltip>
          <template slot="header">δ<sup>25</sup>Mg</template>
        </el-table-column>
        <el-table-column
          prop="delta_25Mg_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="delta_26Mg"
          min-width="180"
          label="δ26Mg"
          show-overflow-tooltip>
          <template slot="header">δ<sup>26</sup>Mg</template>
        </el-table-column>
        <el-table-column
          prop="delta_26Mg_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="delta_29Si"
          min-width="180"
          label="δ29Si"
          show-overflow-tooltip>
          <template slot="header">δ<sup>29</sup>Si</template>
        </el-table-column>
        <el-table-column
          prop="delta_29Si_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="delta_30Si"
          min-width="180"
          label="δ30Si"
          show-overflow-tooltip>
          <template slot="header">δ<sup>30</sup>Si</template>
        </el-table-column>
        <el-table-column
          prop="delta_30Si_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="delta_33S"
          min-width="180"
          label="δ33S"
          show-overflow-tooltip>
          <template slot="header">δ<sup>33</sup>S</template>
        </el-table-column>
        <el-table-column
          prop="delta_33S_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="delta_34S"
          min-width="180"
          label="δ34S"
          show-overflow-tooltip>
          <template slot="header">δ<sup>34</sup>S</template>
        </el-table-column>
        <el-table-column
          prop="delta_34S_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>

        <el-table-column
          prop="delta_36S"
          min-width="180"
          label="δ36S"
          show-overflow-tooltip>
          <template slot="header">δ<sup>36</sup>S</template>
        </el-table-column>
        <el-table-column
          prop="delta_36S_1SE"
          min-width="180"
          label="1SE"
          show-overflow-tooltip>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import dOtopesApi from '@/api/dOtopes'
export default {
  name: 'Search_Detail_dOtopes',
  data() {
    return {
      sampleId: this.$route.query.sampleId,
      table: {
        loading: false,
        data: []
      },
      page: {
        index: 1,
        size: 100,
        total: 0
      }
    }
  },
  methods: {
    getData() {
      if (!this.sampleId) {return}
      this.table.loading = true
      dOtopesApi.getPageData({
        page: this.page.index,
        rows: this.page.size,
        wheres: `[
          {"name":"Sample_ID","value":"${this.sampleId}","displayType":"number"}
        ]`.replace(/\s+/g,"")
      }).then(res => {
        this.table.loading = false
        this.table.data = res.rows
        this.page.total = res.total
      }).catch(err => {
        this.talbe.loading = false
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
  /deep/ .el-pagination {
    margin: 12px 0;
  }
</style>
